import { createReducer, on } from '@ngrx/store';
import { IUser } from '@core/models/interfaces/user.interface';
import * as CoreActions from '@core/store/actions/core.actions';
import { ErrorCodeEnum } from '@core/enums/error-code.enum';

export const CORE_STATE_KEY = 'core';

export interface CoreState {
  isAppLoaded?: boolean;
  errorCode?: ErrorCodeEnum;
  user?: IUser;
  retrieveUserCompleted?: boolean;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  language?: string;
}

const INITIAL_CORE_STATE: CoreState = {
  isAppLoaded: false,
  errorCode: undefined,
  user: undefined,
  retrieveUserCompleted: false,
  accessToken: undefined,
  idToken: undefined,
  refreshToken: undefined,
  language: undefined,
}

export const coreReducer = createReducer(
  INITIAL_CORE_STATE,
  on(CoreActions.setInitData, (state, { language, idToken, accessToken, refreshToken }) => ({
    ...state,
    language,
    idToken,
    accessToken,
    refreshToken,
    isAppLoaded: true
  })),
  on(CoreActions.redirectToLogin, (state) => ({
    ...state,
    errorCode: undefined,
    user: undefined,
    retrieveUserCompleted: false,
    // retrieveTokenCompleted: false,
    accessToken: undefined,
    idToken: undefined,
    refreshToken: undefined
  })),
  on(CoreActions.redirectToErrorPage, (state, { errorCode }) => ({
    ...state,
    errorCode,
    user: undefined,
    retrieveUserCompleted: false,
    accessToken: undefined,
    idToken: undefined,
    refreshToken: undefined
  })),
  on(CoreActions.redirectToDashboard, (state) => ({
    ...state,
    errorCode: undefined
  })),
  on(CoreActions.setToken, CoreActions.updateToken, (state, { idToken, accessToken, refreshToken }) => ({
    ...state,
    idToken,
    accessToken,
    refreshToken,
    // retrieveTokenCompleted: true
  })),
  on(CoreActions.setTokenFailed, (state) => ({
    ...state,
    // retrieveTokenCompleted: false
  })),
  on(CoreActions.setUser, (state, { user }) => ({
    ...state,
    user,
    retrieveUserCompleted: true
  })),
  on(CoreActions.logout, (state) => ({
    ...state,
    user: undefined,
    accessToken: undefined,
    idToken: undefined,
    refreshToken: undefined,
    errorCode: undefined,
    retrieveUserCompleted: false
  }))
);
